.glitch.local-settings {
  position: relative;
  display: flex;
  flex-direction: row;
  background: $ui-secondary-color;
  color: $inverted-text-color;
  border-radius: 8px;
  height: 80vh;
  width: 80vw;
  max-width: 740px;
  max-height: 450px;
  overflow: hidden;

  label, legend {
    display: block;
    font-size: 14px;
  }

  .boolean label, .radio_buttons label {
    position: relative;
    padding-left: 28px;
    padding-top: 3px;

    input {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  span.hint {
    display: block;
    color: $lighter-text-color;
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.glitch.local-settings__navigation__item {
  display: block;
  padding: 15px 20px;
  color: inherit;
  background: lighten($ui-secondary-color, 8%);
  border-bottom: 1px $ui-secondary-color solid;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: background .3s;

  .text-icon-button {
    color: inherit;
    transition: unset;
  }

  &:hover {
    background: $ui-secondary-color;
  }

  &.active {
    background: $ui-highlight-color;
    color: $primary-text-color;
  }

  &.close, &.close:hover {
    background: $error-value-color;
    color: $primary-text-color;
  }
}

.glitch.local-settings__navigation {
  background: lighten($ui-secondary-color, 8%);
  width: 212px;
  font-size: 15px;
  line-height: 20px;
  overflow-y: auto;
}

.glitch.local-settings__page {
  display: block;
  flex: auto;
  padding: 15px 20px 15px 20px;
  width: 360px;
  overflow-y: auto;
}

.glitch.local-settings__page__item {
  margin-bottom: 2px;

  .hint a {
    color: $lighter-text-color;
    font-weight: 500;
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.glitch.local-settings__page__item.string,
.glitch.local-settings__page__item.radio_buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 630px) {
  .glitch.local-settings__navigation {
    width: 40px;
    flex-shrink: 0;
  }

  .glitch.local-settings__navigation__item {
    padding: 10px;

    span:last-of-type {
      display: none;
    }
  }
}

.deprecated-settings-label {
  white-space: nowrap;
}

.deprecated-settings-info {
  text-align: start;

  ul {
    padding: 10px;
    margin-left: 12px;
    list-style: disc inside;
  }

  a {
    color: $lighter-text-color;
    font-weight: 500;
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}
